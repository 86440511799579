import React from "react";

export default function PrivacyPolicy() {
	return (
	  <div className="relative py-20 md:pt-[11rem] bg-white overflow-hidden">
		   
		<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
		  <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
			<svg
			  className="absolute top-12 left-full transform translate-x-32"
			  width={404}
			  height={384}
			  fill="none"
			  viewBox="0 0 404 384"
			>
			  <defs>
				<pattern
				  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
				  x={0}
				  y={0}
				  width={20}
				  height={20}
				  patternUnits="userSpaceOnUse"
				>
				  <rect x={0} y={0} width={4} height={4} className="text-gray-50" fill="currentColor" />
				</pattern>
			  </defs>
			  <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
			</svg>
			<svg
			  className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
			  width={404}
			  height={384}
			  fill="none"
			  viewBox="0 0 404 384"
			>
			  <defs>
				<pattern
				  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
				  x={0}
				  y={0}
				  width={20}
				  height={20}
				  patternUnits="userSpaceOnUse"
				>
				  <rect x={0} y={0} width={4} height={4} className="text-gray-50" fill="currentColor" />
				</pattern>
			  </defs>
			  <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
			</svg>
			<svg
			  className="absolute bottom-12 left-full transform translate-x-32"
			  width={404}
			  height={384}
			  fill="none"
			  viewBox="0 0 404 384"
			>
			  <defs>
				<pattern
				  id="d3eb07ae-5182-43e6-857d-35c643af9034"
				  x={0}
				  y={0}
				  width={20}
				  height={20}
				  patternUnits="userSpaceOnUse"
				>
				  <rect x={0} y={0} width={4} height={4} className="text-gray-50" fill="currentColor" />
				</pattern>
			  </defs>
			  <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
			</svg>
		  </div>
		</div>
		<div className="relative max-w-7xl mx-auto ">
		  <div className="text-lg max-w-prose mx-auto">
			<h1>
			  <span className="block text-base text-center text-yellow-500 font-semibold tracking-wide uppercase">
				The Director
			  </span>
			  <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-hn-ex">
				Privacy Policy
			  </span>
			</h1>
		  </div>
		  <div className="mt-16 prose prose-indigo prose-lg text-gray-600 mx-auto">
			
		  	<p>This Privacy Policy describes the data protection practices of FRONTROW GLOBAL LIMITED ("FRONTROW," "The Director," "we," "us," or "our"). This Privacy Policy applies to information that we collect and use about you when you access or use our website, mobile application, or other online or mobile service that links to or otherwise presents this Privacy Policy to you. We refer to these products and services collectively as the “Services.”</p>
		  	<br />
		  	<p>PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES.</p>

			<h2>Information We Collect</h2>
			<p>We collect information about you through the means discussed below. Please note that we need certain types of information so that we can provide the Services to you. If you do not provide us with such information, or ask us to delete it, you may no longer be able to access or use our Services.</p>

			<h3 className="text-yellow-500">1. Information You Provide to Us</h3>
			<p>We collect a variety of information that you provide directly to us. For example, we collect information from you through:</p>
			<ul>
				<li>The Services you use or processing your orders</li>
				<li>Requests or questions you submit to us via online forms, email, or otherwise</li>
				<li>Your participation in sweepstakes, contests, or surveys</li>
				<li>Any reviews that you submit about the Services</li>
				<li>Account registration and administration of your account</li>
				<li>Uploads or posts to the Services</li>
				<li>Requests for customer support and technical assistance</li>
			</ul>

			<p><strong>Information about you.</strong> While parts of the Services may not require you to provide any information that can directly identify you by name (such as if you choose to browse the website without logging in), the specific types of information we collect will depend upon the Services you use, how you use them, and the information you choose to provide. The types of data we collect directly from you includes:</p>

			<ul>
				<li>Email address</li>
				<li>Full Name</li>
				<li>Log-in credentials, if you create a director account</li>
				<li>Information about purchases or other transactions with us</li>
				<li>Information about your customer service interactions with us</li>
				<li>Classes in which you enroll</li>
				<li>Any other information you choose to directly provide to us in connection with your use of the Services</li>
			</ul>

			<p>
				<strong>Information about others.</strong> If you request that your purchase be provided to someone other than yourself (such as a gift recipient), we use the information you provide about the other person to fulfill the order.
			</p>

			<h3 className="text-yellow-500">2. Information We Collect In Automated Means</h3>
			<p>When you use our Services, we collect certain information as described in this Section. As discussed further below, we and our service providers (which are third party companies that work on our behalf) may use a variety of technologies, including cookies and similar tools, to assist in collecting this information.</p>

			<p><strong>Websites.</strong> When you use our website, we collect and analyze information such as your IP address, browser types, browser language, operating system, software and hardware attributes (including device IDs) referring and exit pages and URLs, the number of clicks, pages viewed and the order of those pages, date and time of use, content watched, total minutes watched, error logs, and other similar information about how you use the website.</p>

			<p><strong>Mobile Applications.</strong> When you use The Director mobile application or software ("app"), we automatically receive certain information about the mobile phone, tablet, or computer used to access the app, including device identifiers, date and time of use, content watched, total minutes watched, error logs, and other similar information about how you use the app.</p>

			<p><strong>Location Information.</strong> When you use the Services, we and our service providers may automatically collect general location information (e.g., IP address, city/state and/or postal code associated with an IP address, city/state) from your computer or mobile device.</p>



			<h2>How We Use Your Information</h2>

			<p>We, or our service providers, use your information for various purposes depending on the types of information we have collected from and about you, in order to:</p>
			<ul>
				<li>Complete a purchase or provide the Services you have requested, including invoicing and accounting</li>
				<li>Respond to your request for information and provide you with more effective and efficient customer service</li>
				<li>Provide you with updates and information about classes in which you have enrolled</li>
				<li>Contact you by email, postal mail, or phone regarding The Director and third-party products, services, surveys, research studies, promotions, special events and other subjects that we think may be of interest to you</li>
				<li>Customize the advertising and content you see on the Services</li>
				<li>Help us better understand your interests and needs, and improve the Services, including through research and reports, and test and create new products, features, and services</li>
				<li>Secure our websites and applications, and resolve app crashes and other issues being reported</li>
				<li>Comply with any procedures, laws, and regulations which apply to us where it is necessary for our legitimate interests or the legitimate interests of others</li>
				<li>Establish, exercise, or defend our legal rights where it is necessary for our legitimate interests or the legitimate interests of others</li>
				<li>Combined Information.For the purposes discussed in this Privacy Policy, we may combine the information that we collect through the Services with information that we receive from other sources, both online and offline, and use such combined information in accordance with this Privacy Policy.</li>
			</ul>
			
			<p>Aggregate/Anonymous Data. We may aggregate and/or anonymize any information collected</p>

			<p>through the Services so that such information can no longer be linked to you or your device. We may use such information for any purpose, including without limitation for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, and sponsors.
			</p>



			<h2>Retention of Your Information</h2>
			<p>We keep your information for no longer than necessary for the purposes for which it is processed. The length of time for which we retain information depends on the purposes for which we collected and use it and/or as required to comply with applicable laws.</p>

			<h2>How We Protect Your Information</h2>
			<p>The Director takes technical and organizational security measures to protect the information provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. Please keep this in mind when disclosing any information to The Director via the Internet.</p>

			<h2>Third Party Links and Features</h2>
			<p>The Services contain links to third-party websites such as social media sites, and also contain third-party plug-ins (such as the Facebook “like” button and Twitter “follow” button). If you choose to use these sites or features, you may disclose your information not just to those third-parties, but also to their users and the public more generally depending on how their services function. We are not responsible for the content or practices of those websites or services. The collection, use, and disclosure of your information will be subject to the privacy policies of the third party websites or services, and not this Privacy Policy. We urge you to read the privacy and security policies of these third-parties.</p>


			<h2>Contact Information</h2>
			<p>
			Please feel free to contact us if you have any questions about The Director's Privacy Policy or the information practices of the Services.
			<a href="mailto:support@thedirector.io">support@thedirector.io</a>
			</p>


			
		  </div>
		</div>
	  </div>
	)
  }
  